import React from 'react';
import Button from '@material-ui/core/Button';
import FlipMove from 'react-flip-move';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import BarCodeInput, { checkUsingRegex } from 'components/BarCodeInput';
import AlertDialog from 'components/AlertDialog';
import { PropTypes } from 'prop-types';
import styles from '../styles';
import { REGEX_PRODUCT } from 'constants/Regexes';
import ProductComponent from '../../ProductsLoss/components/ProductComponent';
import { resetStore } from 'actions/ProductsLoss';
import { getProductsInfos, errorClear } from 'actions/ProductsInfo';
import { connect } from 'react-redux';
import { errorNotification, sendNotification } from 'actions/Notifications';
import DCSelect from 'containers/DCSelect';
import { compose } from 'redux';
import { confirmReturnProducts } from 'actions/ProductsReturn';

class ProductsReturnContainer extends React.Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.success && prevState.step === 1) {
			return {
				...prevState,
				productsList: {},
				busyScanner: false,
				step: 0,
				total: 0,
			};
		}
		return null;
	}

	constructor(props) {
		super(props);

		this.state = {
			productsList: {},
			busyScanner: false,
			total: 0,
			step: 0,
		};
	}

	componentDidMount() {
		this.props.getProductsInfos();
	}

	componentDidUpdate() {
		if (this.props.success) {
			this.props.resetStore();
		}
	}

	getSkuFromBarcode = barcode =>
		String.fromCharCode(96 + parseInt(barcode.substr(1, 2))).toUpperCase() +
		barcode.substr(3, 3);

	handleRegisterSubmission = value => {
		let sku = this.getSkuFromBarcode(value);
		if (!this.props.products[sku]) {
			sku = Object.keys(this.props.products).find(
				key => this.props.products[key].barcode === Number(value)
			);
		}

		if (!this.state.productsList[sku]) {
			this.setState(prevState => ({
				...prevState,
				total: prevState.total + 1,
				productsList: {
					...prevState.productsList,
					[sku]: { ...this.props.products[sku], quantity: 1 },
				},
			}));
		} else {
			this.setState(prevState => ({
				...prevState,
				total: prevState.total + 1,
				productsList: {
					...prevState.productsList,
					[sku]: {
						...prevState.productsList[sku],
						quantity: prevState.productsList[sku].quantity + 1,
					},
				},
			}));
		}
	};

	handleCheckValid = value => {
		if (!this.state.busyScanner && checkUsingRegex(value, REGEX_PRODUCT)) {
			this.setState(prevState => ({
				...prevState,
				busyScanner: true,
			}));
			this.props.sendNotification({
				type: 'success',
			});
			setTimeout(() => {
				this.setState(prevState => ({
					...prevState,
					busyScanner: false,
				}));
			}, 1000);
			return true;
		}
		return false;
	};

	handleClear = () => {
		this.setState(prevState => ({
			...prevState,
			productsList: {},
			busyScanner: false,
			total: 0,
		}));
	};

	handleFinish = () => {
		const { productsList } = this.state;
		const items = Object.keys(this.state.productsList).map(key => ({
			_id: productsList[key]._id,
			sku: key,
			name: productsList[key].name,
			quantity: Number(productsList[key].quantity),
		}));
		this.props.confirmReturnProducts(items);
	};

	handleModalClose = () => {
		this.props.errorClear();
	};

	handleStart = () => {
		this.setState(prevState => ({
			...prevState,
			step: 1,
		}));
	};

	sendErrorNotification = value => value;

	renderDCSelect() {
		const { classes } = this.props;
		return (
			<Grid container justify="center" alignItems="center" className={classes.dcSelect}>
				<Grid item xs={4}>
					<Card>
						{!this.props.products ? (
							<CircularProgress size={50} />
						) : (
							<CardContent>
								<Grid container direction="column" alignItems="center">
									<Grid item>
										<h2>Escolha o centro de distribuição</h2>
									</Grid>
									<Grid item className={classes.input}>
										<DCSelect />
									</Grid>
									<Grid item justify="center">
										<Button
											variant="raised"
											color="primary"
											onClick={this.handleStart}
											className={classes.start}
										>
											Ok
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						)}
					</Card>
				</Grid>
			</Grid>
		);
	}

	renderCard() {
		const { classes } = this.props;
		return (
			<Grid container justify="space-between" alignItems="flex-end" spacing={24}>
				<Grid item xs={7}>
					<Card>
						{!this.props.products ? (
							<CircularProgress size={50} />
						) : (
							<CardContent>
								<Grid container direction="column">
									<Grid item>Leia produtos</Grid>
									<Grid item className={classes.input}>
										<BarCodeInput
											onMatch={this.handleRegisterSubmission}
											checkValid={this.handleCheckValid}
											onError={this.sendErrorNotification}
											autoFocus
											keepFocus
											debounce
										/>
									</Grid>
									<Grid item>
										<Grid container justify="space-between">
											<Button
												variant="raised"
												color="secondary"
												onClick={this.handleClear}
											>
												Limpar
											</Button>
											<Button
												variant="raised"
												color="primary"
												onClick={this.handleFinish}
											>
												Finalizar
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						)}
					</Card>
				</Grid>
				<Grid item xs={5} alignItems="center">
					<Card>
						<CardContent>
							<h2>Total lido: {this.state.total}</h2>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		);
	}

	renderProductsTable() {
		const { productsList } = this.state;
		const { classes } = this.props;
		return (
			<Grid container xs={12} justify="center" className={classes.productCard}>
				<Grid item xs={12} className={classes.productCard}>
					<Card className={classes.productCard}>
						{this.state.productsList && (
							<CardContent className={classes.productCard}>
								<Grid container xs={12} direction="column">
									<FlipMove duration={300} easing="ease-out">
										{Object.keys(productsList).map((sku, index) => (
											<ProductComponent
												index={index}
												sku={sku}
												name={productsList[sku].name}
												imageUrl={productsList[sku].imageUrl}
												quantity={productsList[sku].quantity}
												color={productsList[sku].color}
												key={productsList[sku]._id}
											/>
										))}
									</FlipMove>
								</Grid>
							</CardContent>
						)}
					</Card>
				</Grid>
			</Grid>
		);
	}

	renderErrorModal() {
		const { error } = this.props;
		return (
			<AlertDialog
				open={error}
				title={`Erro: ${error}`}
				children={`Clique em 'FINALIZAR' para tentar novamente`}
				onClose={this.handleModalClose}
				actions={[
					{
						onClick: this.handleModalClose,
						title: 'Ok',
						type: 'primary',
						autoFocus: true,
					},
				]}
			/>
		);
	}

	renderLoading() {
		const { classes } = this.props;
		return (
			<Grid
				container
				alignItems="center"
				justify="center"
				className={classes.loadingContainer}
			>
				<CircularProgress size={50} />
			</Grid>
		);
	}

	render() {
		const { productsList } = this.state;
		return (
			<Grid container direction="column">
				{this.props.loading && this.renderLoading()}
				{!this.props.loading && this.state.step === 0 && this.renderDCSelect()}
				{!this.props.loading && this.state.step === 1 && this.renderCard()}
				<br />
				<br />
				{!this.props.loading &&
					this.state.step === 1 &&
					Object.keys(productsList).length > 0 &&
					this.renderProductsTable()}
				{this.renderErrorModal()}
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.productsReturn.loading,
	error: state.productsReturn.error,
	success: state.productsReturn.success,
	products: state.productsReturn.products,
});

ProductsReturnContainer.propTypes = {
	getProductsInfos: PropTypes.func.isRequired,
};

ProductsReturnContainer.defaultProps = {};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, {
		confirmReturnProducts,
		getProductsInfos,
		sendNotification,
		errorClear,
		resetStore,
	})
)(ProductsReturnContainer);
