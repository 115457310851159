export const REGEX_FROZEN_WMS_POSITION = /^([A-Z]{3})[-]([0-9]{2})$/;
export const REGEX_SNACKS_WMS_POSITION = /^([A-Z]{3})[-]([A-Z]{1})[-]([0-9]{2})$/;
export const REGEX_PICKING_BOX_LABEL = /(LVB(?:P|M|G)[0-9]{4})/i;
export const REGEX_PICKING_ORDER = /(\d{5,})/;
export const REGEX_PACKING_CODE = /^(\d+)-([A-Z])-(\d{3})$/;
export const REGEX_FROZEN_PACKING_CODE = /^(\d+)-(F)-(\d{3})$/;
export const REGEX_SNACKS_PACKING_CODE = /^(\d+)-([A-Z]{1})-(\d{3})$/;
export const REGEX_FROZEN_PACKING_CODE_OR_WMS_POSITION = new RegExp(
  `(${REGEX_FROZEN_WMS_POSITION.source})|(${REGEX_FROZEN_PACKING_CODE.source})`
);
export const REGEX_SNACKS_PACKING_CODE_OR_WMS_POSITION = new RegExp(
  `(${REGEX_SNACKS_WMS_POSITION.source})|(${REGEX_SNACKS_PACKING_CODE.source})`
);
export const REGEX_PRODUCT = /^(\d{12})(\d{1})?$/;
export const REGEX_PACKING_BOX_OR_ORDER = new RegExp(
  `${REGEX_PICKING_BOX_LABEL.source}|${REGEX_PICKING_ORDER.source}`,
  "i"
);
export const PACKAGE_BARCODE_REGEX = /^(TP|AL|FE|tp|al|fe)([A-Za-z][A-Za-z0-9]\d{2})(\d{3})(\d{4})(\d{2})(\d{2})([A-Za-z0-9]{5})$/;

export const EAN14REGEX = /^([1-8])789(\d{10})$/;
export const LIVUP_GS1_MANUFACTURER_CODE_REGEX = /^78986373\d{5}$/;
