import axios from 'axios'

import { INVENTORY_API_ADDRESS } from '../constants/InventoryAPI'

const inventoryApi = axios.create({
  baseURL: INVENTORY_API_ADDRESS,
  headers: { 'Content-Type': 'application/json' },
})

inventoryApi.interceptors.request.use(config => {
  if (!config.headers.Authorization) {
    const authToken = localStorage.getItem('gandauthToken')
    config.headers.Authorization = authToken ? `Bearer ${authToken}` : ''
  }
  return config
})

export { inventoryApi }
