import { inventoryApi } from '../api/inventoryApi'
import {
  CLOSE_PALLET_ERROR,
  CLOSE_PALLET_REQUEST,
  CLOSE_PALLET_RESPONSE,
  GET_PALLETS_ERROR,
  GET_PALLETS_REQUEST,
  GET_PALLETS_RESPONSE,
  RESET_CLOSE_PALLETS_STORE,
  SET_PALLET,
} from '../constants/ActionTypes'
import { getAxiosErrorResponseMessage } from '../utils/getAxiosErrorResponseMessage'
import { handleRequestError } from './ErrorHandling'

export const getPallets = () => async dispatch => {
  try {
    dispatch({
      type: GET_PALLETS_REQUEST,
    })

    const response = await inventoryApi.get('/pallets/get-all', {
      params: { open: true },
    })

    dispatch({
      type: GET_PALLETS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = getAxiosErrorResponseMessage(err)
    dispatch({
      type: GET_PALLETS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const setPallet = pallet => dispatch => {
  dispatch({
    type: SET_PALLET,
    payload: {
      pallet,
    },
  })
}

export const closePallet = (pallet, boxCode) => async dispatch => {
  try {
    dispatch({
      type: CLOSE_PALLET_REQUEST,
    })

    const response = await inventoryApi.put(
      `/pallets/close-pallet/${pallet._id}`,
      { boxCode }
    )

    dispatch({
      type: CLOSE_PALLET_RESPONSE,
      payload: {
        palletClosed: response.status === 200,
      },
    })
  } catch (err) {
    if (err.response?.status === 304) {
      dispatch({
        type: CLOSE_PALLET_RESPONSE,
        payload: {
          alreadyClosed: true,
        },
      })
      return
    }
    dispatch({
      type: CLOSE_PALLET_ERROR,
      payload: {
        error: getAxiosErrorResponseMessage(err),
      },
    })
  }
}

export const resetStore = () => dispatch => {
  dispatch({
    type: RESET_CLOSE_PALLETS_STORE,
  })
}
