import axios from 'axios'

import { sendNotification } from '../actions/Notifications'
import { inventoryApi } from '../api/inventoryApi'
import {
  ADD_BOX_TO_PALLET_ERROR,
  ADD_BOX_TO_PALLET_REQUEST,
  ADD_BOX_TO_PALLET_RESPONSE,
  GET_PALLETS_ERROR,
  GET_PALLETS_REQUEST,
  GET_PALLETS_RESPONSE,
  RESET_INVENTORY_INPUT_STORE,
} from '../constants/ActionTypes'
import { INVENTORY_API_ADDRESS } from '../constants/InventoryAPI'
import { getAxiosErrorResponseMessage } from '../utils/getAxiosErrorResponseMessage'
import { handleRequestError } from './ErrorHandling'

export const getPallets = () => async dispatch => {
  try {
    dispatch({
      type: GET_PALLETS_REQUEST,
    })

    const response = await inventoryApi.get('/pallets/get-all', {
      params: { open: true, sent: false },
    })

    dispatch({
      type: GET_PALLETS_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = getAxiosErrorResponseMessage(err)
    dispatch({
      type: GET_PALLETS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const addBoxToPallet = (palletId, boxCode) => async dispatch => {
  try {
    dispatch({
      type: ADD_BOX_TO_PALLET_REQUEST,
    })

    const response = await inventoryApi.post('/pallets/input', {
      palletId,
      boxCode,
    })

    dispatch(
      sendNotification({
        type: 'success',
        message: 'Sucesso! A caixa foi adicionada ao pallet desejado.',
      })
    )
    dispatch({
      type: ADD_BOX_TO_PALLET_RESPONSE,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    const errorMessage = getAxiosErrorResponseMessage(err)
    dispatch({
      type: ADD_BOX_TO_PALLET_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err, true, '/pallets/input'))
  }
}

export const resetInventoryInputStore = () => async dispatch => {
  dispatch({
    type: RESET_INVENTORY_INPUT_STORE,
  })
}
