import { sendNotification } from '../actions/Notifications';
import { inventoryApi } from '../api/inventoryApi';
import {
	PRODUCTS_LOSS_FINISH_ERROR,
	PRODUCTS_LOSS_FINISH_REQUEST,
	PRODUCTS_LOSS_FINISH_RESPONSE,
	PRODUCTS_LOSS_RESET,
} from '../constants/ActionTypes';
import { getAxiosErrorResponseMessage } from '../utils/getAxiosErrorResponseMessage';

export const finishProductsLossScanning = items => async (dispatch, getState) => {
	const centerId = getState().distributionCenters.selected;
	const centerKind = getState().distributionCenters.selectedKind;

	try {
		dispatch({
			type: PRODUCTS_LOSS_FINISH_REQUEST,
		});
		const response = await inventoryApi.post(
			'orders/loss',
			{
				origin: centerId,
				originKind: centerKind,
				items,
			},
		);
		dispatch({
			type: PRODUCTS_LOSS_FINISH_RESPONSE,
			payload: response.data,
		});
		dispatch(sendNotification({ type: 'success', message: 'Operação finalizada!' }));
	} catch (err) {
		dispatch({
			type: PRODUCTS_LOSS_FINISH_ERROR,
			payload: {
				error: getAxiosErrorResponseMessage(err),
			},
		});
	}
};

export const resetStore = () => async dispatch => {
	dispatch({
		type: PRODUCTS_LOSS_RESET,
	});
};
