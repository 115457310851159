import { handleRequestError } from 'actions/ErrorHandling';
import { INVENTORY_API_ADDRESS } from 'constants/InventoryAPI';
import moment from 'moment';
import {
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_REQUEST,
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_RESPONSE,
	PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_ERROR,
} from 'constants/ActionTypes';
import { sendNotification } from 'actions/Notifications';
import { inventoryApi } from '../api/inventoryApi';
import { getAxiosErrorResponseMessage } from '../utils/getAxiosErrorResponseMessage';

export const confirmReturnProducts = items => async (dispatch, getState, api) => {
	try {
		dispatch({
			type: PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_REQUEST,
		});

		const centerId = getState().distributionCenters.selected;
		const centerKind = getState().distributionCenters.selectedKind;
		const response = await inventoryApi.post('/orders/confirm-return-product', {
			items,
			origin: centerId,
			originKind: centerKind
		})

		dispatch({
			type: PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_RESPONSE,
			payload: response.data,
		});
		dispatch(sendNotification({ type: 'success', message: 'Operação finalizada!' }));
	} catch (err) {
		const errorMessage = getAxiosErrorResponseMessage(err)
		dispatch({
			type: PRODUCTS_RETURN_CONFIRM_RETURN_RODUCTS_ERROR,
			payload: {
				error: errorMessage,
			},
		});
		dispatch(handleRequestError(err));
	}
};
