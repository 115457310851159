import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import IssueIcon from '@material-ui/icons/Publish';

const styles = theme => ({
  root: {
    marginTop: '7px',
    marginBottom: '7px',
    padding: '8px 12px 8px 12px',
    flex: 1,
  },
  container: {},
  invoiceIndex: {
    color: '#e98081',
    marginBottom: '6px',
    fontSize: '16px',
  },
  divider: {
    width: '100%',
    marginTop: '1%',
    marginBottom: '2%',
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: '#e4e4e4',
  },
  verticalDivider: {
    marginTop: '12px',
    marginBottom: '12px',
    backgroundColor: '#e4e4e4',
    width: '1px',
  },
  itemName: {
    marginBottom: '6px',
    color: '#2a3644',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  itemField: {
    color: '#888888',
    fontSize: '14px',
    lineHeight: '1.76429em',
  },
  detailPre: {
    backgroundColor: '#d7d7d7',
    fontSize: '14px',
    borderRadius: '3px',
    padding: '7px',
    lineHeight: '1.76429em',
    whiteSpace: 'pre-wrap',
    overflowY: 'auto',
    maxHeight: '180px'
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
    marginBottom: 4,
  },
  actionContainer: {
    minWidth: '150px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  actionButton: {
    padding: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    flexDirection: 'column',
    '&:hover': {
      background: 'transparent',
      opacity: '0.7',
    },
  },
  actionText: {
    color: '#008fa8',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  actionIcon: {
    color: '#008fa8',
  },
  bankSlipBtn: {
    marginTop: 4,
    '&:hover': {
      background: 'transparent',
      opacity: '0.7',
    },
  }
});

const Invoice = props => {
  const { invoice, classes, onInvoiceRequested } = props;
  const isRequestInvoice = invoice.isRequestInvoice || false;

  const handleIssue = useCallback(() => {
    if (onInvoiceRequested) {
      onInvoiceRequested(invoice.order);
    }
  }, [invoice, onInvoiceRequested]);

  const handleDownload = useCallback(() => {
    if (!invoice.pdf) return false;
    window.open(invoice.pdf);
  }, [invoice]);

  return (
    <Paper className={classes.cell}>
      <ListItem className={classes.root}>
        <Grid container direction="column" className={classes.container}>
          <Typography className={classes.invoiceIndex}>
            Pedido: {invoice.order || '---'}
          </Typography>
          <Typography className={classes.itemField}>
            Tipo de documento: {invoice.docType || '---'}
          </Typography>
          <Typography className={classes.itemField}>
            Status: {invoice.status || '---'}
          </Typography>
          <Typography className={classes.itemField}>
            Detalhes:
            <pre className={classes.detailPre}>
              <span>{invoice.details || 'Sem informações ainda'}</span>
            </pre>
          </Typography>
          {invoice.bankSlipUrl && (
            <div>
              <Typography className={classes.itemField}>
                Informações do boleto:
              </Typography>
              <Button
                href={invoice.bankSlipUrl}
                target="_blank"
                variant="outlined"
                className={classes.bankSlipBtn}
              >
                <DownloadIcon className={classes.actionIcon} />
                <Typography className={classes.actionText}>
                  Download do boleto
                </Typography>
              </Button>
            </div>
          )}
        </Grid>
      </ListItem>
      <div className={classes.verticalDivider} />
      <div className={classes.actionContainer}>
        {!invoice.invoiceRequested && (
          <Button
            variant="text"
            className={classes.actionButton}
            onClick={handleIssue}
            disabled={isRequestInvoice}
          >
            {!isRequestInvoice && <IssueIcon className={classes.actionIcon} />}
            <Typography className={classes.actionText}>
              {isRequestInvoice ? 'Emitindo...' : 'Emitir'}
            </Typography>
          </Button>
        )}
        {invoice.invoiceAuthorized && (
          <Button
            variant="text"
            className={classes.actionButton}
            onClick={handleDownload}
          >
            <DownloadIcon className={classes.actionIcon} />
            <Typography className={classes.actionText}>Download</Typography>
          </Button>
        )}
      </div>
    </Paper>
  );
};

Invoice.propTypes = {
  classes: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  onInvoiceRequested: PropTypes.func,
};

Invoice.defaultProps = {
  onInvoiceRequested: () => {},
};

export default withStyles(styles)(Invoice);
