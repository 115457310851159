import { inventoryApi } from '../api/inventoryApi'
import {
  DELETE_PALLET_ERROR,
  DELETE_PALLET_REQUEST,
  DELETE_PALLET_RESPONSE,
  GET_CLOSED_PALLETS_ERROR,
  GET_CLOSED_PALLETS_REQUEST,
  GET_CLOSED_PALLETS_RESPONSE,
} from '../constants/ActionTypes'
import { getAxiosErrorResponseMessage } from '../utils/getAxiosErrorResponseMessage'
import { handleRequestError } from './ErrorHandling'

export const getClosedPallets = () => async dispatch => {
  try {
    dispatch({
      type: GET_CLOSED_PALLETS_REQUEST,
    })

    const response = await inventoryApi.get('/pallets/closed-pallets')

    dispatch({
      type: GET_CLOSED_PALLETS_RESPONSE,
      payload: {
        data: response.data.pallets,
      },
    })
  } catch (err) {
    const errorMessage = getAxiosErrorResponseMessage(err)

    dispatch({
      type: GET_CLOSED_PALLETS_ERROR,
      payload: {
        error: errorMessage,
      },
    })
    dispatch(handleRequestError(err))
  }
}

export const deletePallet = pallet => async dispatch => {
  try {
    dispatch({
      type: DELETE_PALLET_REQUEST,
    })

    const response = await inventoryApi.delete(
      `/pallets/delete-pallet?palletId=${pallet._id}`
    )

    dispatch({
      type: DELETE_PALLET_RESPONSE,
      payload: {
        palletClosed: response.status === 200,
      },
    })

    dispatch(getClosedPallets()) // reload the pallets
  } catch (err) {
    const errorMessage = getAxiosErrorResponseMessage(err)

    dispatch({
      type: DELETE_PALLET_ERROR,
      payload: {
        error: errorMessage,
      },
    })
  }
}
